import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertHelper {

  constructor(
    private alertController: AlertController
  ) { }

  async presentAlertConfirm(message: string, confirmCallback: any) {
    const alert = await this.alertController.create({
      backdropDismiss:false,
      message: message,
      buttons: [
        {
          text: 'YES',
          cssClass: 'secondary',
          handler: () => {
            if (confirmCallback) {
              confirmCallback();
            }
          }
        }, {
          text: 'NO',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  async presentAlertConfirmWithButton(message: string, confirmCallback: any, yes:string, no: string) {
    const alert = await this.alertController.create({
      backdropDismiss:false,
      message: message,
      buttons: [
        {
          text: no,
          role: 'cancel'
        },{
          text: yes,
          cssClass: 'secondary',
          handler: () => {
            if (confirmCallback) {
              confirmCallback();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlert(title:string,message:string) {
    const alert = await this.alertController.create({
      subHeader: title,
      message: message,
      backdropDismiss:false,
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentAlertWithCallback(title:string,message:string, buttonText:string, cssClass:string|string[], confirmCallback: any) {
    const alert = await this.alertController.create({
      subHeader: title,
      message: message,
      backdropDismiss:false,
      buttons: [
        {
          text: buttonText,
          cssClass: 'secondary',
          handler: () => {
            if (confirmCallback) {
              alert.dismiss();
              confirmCallback();
            }
          }
        }
      ]
    });

    if(cssClass && cssClass.length>0){
      alert.cssClass=cssClass;
    }

    await alert.present();
  }

  async presentAlertNoTitle(message:string) {
    const alert = await this.alertController.create({
      message: message,
      backdropDismiss:false,
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentAlertNoTitleWithCallback(message:string, cssClass:string|string[], confirmCallback: any) {
    const alert = await this.alertController.create({
      message: message,
      backdropDismiss:false,
      buttons: [
        {
          text: 'OK',
          cssClass: 'secondary',
          handler: () => {
            if (confirmCallback) {
              confirmCallback();
            }
          }
        }
      ]
    });
    
    if(cssClass && cssClass.length>0){
      alert.cssClass=cssClass;
    }

    await alert.present();
  }


}
