import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  { path: '', redirectTo: environment.formType == 'hc' ? 'startform' : 'formlist', pathMatch: 'full' },
  //{ path: 'user', loadChildren: './user/user.module#UserPageModule' },
  //{ path: 'form', loadChildren: './form/form.module#FormPageModule' },
  { path: 'formlist', loadChildren: './main/main.module#MainPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'studyLeave', loadChildren: './studyLeaveForm/studyLeave/studyLeave.module#StudyLeavePageModule' },
  { path: 'sectiona', loadChildren: './studyLeaveForm/sectiona/sectiona.module#SectionAPageModule' },
  { path: 'sectionb', loadChildren: './studyLeaveForm/sectionb/sectionb.module#SectionBPageModule' },
  { path: 'sectionc', loadChildren: './studyLeaveForm/sectionc/sectionc.module#SectionCPageModule' },
  // { path: 'sectiond', loadChildren: './studyLeaveForm/sectiond/sectiond.module#SectionDPageModule' },
  { path: 'sectiond', loadChildren: './studyLeaveForm/sectione/sectione.module#SectionEPageModule' },
  // { path: 'sectionf', loadChildren: './studyLeaveForm/sectionf/sectionf.module#SectionFPageModule' },
  { path: 'reimbursement', loadChildren: './studyLeaveForm/reimbursement/reimbursement.module#ReimbursementModule' },

  { path: 'manageApprentice', loadChildren: './apprenticeshipForm/manageApprentice/manageApprentice.module#ManageApprenticeModule' },
  { path: 'lineManager', loadChildren: './apprenticeshipForm/lineManager/lineManager.module#LineManagerModule' },
  { path: 'answerQuestion', loadChildren: './apprenticeshipForm/answerQuestion/answerQuestion.module#answerQuestionModule' },
  { path: 'criteria', loadChildren: './apprenticeshipForm/criteria/criteria.module#CriteriaModule' },
  { path: 'agreement', loadChildren: './apprenticeshipForm/agreement/agreement.module#AgreementModule' },

  // RA Form
  { path: 'intro', loadChildren: './covidRAForm/intro/intro.module#IntroPageModule' },
  { path: 'raFormList', loadChildren: './covidRAForm/raFormList/raFormList.module#RAFormListPageModule' },
  //// Part A
  { path: 'partAMainPage', loadChildren: './covidRAForm/partAForms/raFormMain/raFormMain.module#RAFormMainPageModule' },
  { path: 'selfAssessment', loadChildren: './covidRAForm/partAForms/selfAssessment/selfAssessment.module#SelfAssessmentPageModule' },
  { path: 'doctorsInTraining', loadChildren: './covidRAForm/partAForms/doctorsInTraining/doctorsInTraining.module#DoctorsInTrainingPageModule' },  
  { path: 'highRiskGroups', loadChildren: './covidRAForm/partAForms/highRiskGroups/highRiskGroups.module#HighRiskGroupsPageModule' },
  { path: 'moderateRiskGroups', loadChildren: './covidRAForm/partAForms/moderateRiskGroups/moderateRiskGroups.module#ModerateRiskGroupsPageModule' },
  { path: 'vaccination', loadChildren: './covidRAForm/partAForms/vaccination/vaccination.module#VaccinationPageModule' },
  { path: 'health', loadChildren: './covidRAForm/partAForms/health/health.module#HealthPageModule' },
  { path: 'distribution', loadChildren: './covidRAForm/partAForms/distribution/distribution.module#DistributionPageModule' },
  { path: 'submitPartA', loadChildren: './covidRAForm/partAForms/signOff/signOff.module#SignOffPageModule' },
  //// Part B
  { path: 'partBMainPage', loadChildren: './covidRAForm/partBForms/raBFormMain/raBFormMain.module#RABFormMainPageModule' },
  { path: 'keyConsiderations', loadChildren: './covidRAForm/partBForms/keyConsiderations/keyConsiderations.module#KeyConsiderationsPageModule' },
  { path: 'trainingAssessment', loadChildren: './covidRAForm/partBForms/trainingAssessment/trainingAssessment.module#TrainingAssessmentPageModule' },
  { path: 'assessmentOutcome', loadChildren: './covidRAForm/partBForms/assessmentOutcome/assessmentOutcome.module#AssessmentOutcomePageModule' },
  { path: 'assessmentActions', loadChildren: './covidRAForm/partBForms/assessmentActions/assessmentActions.module#AssessmentActionsPageModule' },
  { path: 'assessmentActions2b', loadChildren: './covidRAForm/partBForms/assessmentActions2b/assessmentActions2b.module#AssessmentActions2bPageModule' },
  { path: 'partBdistribution', loadChildren: './covidRAForm/partBForms/distribution/distribution.module#DistributionPageModule' },
  { path: 'riskAssessmentReviewed', loadChildren: './covidRAForm/partBForms/riskAssessmentReviewed/riskAssessmentReviewed.module#RiskAssessmentReviewedPageModule' },
  { path: 'submitPartB', loadChildren: './covidRAForm/partBForms/signOff/signOff.module#SignOffPageModule' },

  // { path: 'assessmentActions2', loadChildren: './covidRAForm/assessmentActions2/assessmentActions2.module#AssessmentActions2PageModule' },
  // { path: 'signOff', loadChildren: './covidRAForm/signOff/signOff.module#SignOffPageModule' },

  //booking
  { path: 'bookingintro', loadChildren: './fittestbooking/info/bookingintro.module#BookingIntroPageModule' },
  { path: 'bookingformlist', loadChildren: './fittestbooking/mainPage/bookingFormList.module#BookingFormListPageModule' },
  { path: 'fitMaskTestInfo', loadChildren: './fittestbooking/fitMaskTestInfo/fitMaskTestInfo.module#FitMaskTestInfoPageModule' },
  { path: 'profileinfo', loadChildren: './fittestbooking/profileInfo/profileInfo.module#ProfileInfoPageModule' },
  { path: 'bookinginfo', loadChildren: './fittestbooking/bookingInfo/bookingInfo.module#BookingInfoPageModule' },
  { path: 'bookinglocation', loadChildren: './fittestbooking/bookingLocation/bookingLocation.module#BookingLocationPageModule' },
  { path: 'bookingdate', loadChildren: './fittestbooking/datecalendar/datecalendar.module#DateCalendarPageModule' },
  { path: 'bookingslot', loadChildren: './fittestbooking/bookingSlot/bookingSlot.module#BookingSlotPageModule' },

  //Contact Form
  { path: 'startform', loadChildren: './honoraryContractRequestForm/start-off-form/start-off-form.module#StartOffFormModule' },
  { path: 'substantive', loadChildren: './honoraryContractRequestForm/substantive/substantive.module#SubstantiveModule' },
  { path: 'nhstonhs', loadChildren: './honoraryContractRequestForm/nhs-to-nhs/nhs-to-nhs.module#NhsToNhsModule' },
  { path: 'extension', loadChildren: './honoraryContractRequestForm/extension/extension.module#ExtensionModule' },
  { path: 'typeofrequest', loadChildren: './honoraryContractRequestForm/type-of-request/type-of-request.module#TypeOfRequestModule' },
  { path: 'sponsoringmanager', loadChildren: './honoraryContractRequestForm/sponsoring-manager-detail/sponsoring-manager-detail.module#SponsoringManagerDetailModule' },
  { path: 'honorarycontract', loadChildren: './honoraryContractRequestForm/honorary-contract-detail/honorary-contract-detail.module#HonoraryContractDetailModule' },
  { path: 'honorarycandidate', loadChildren: './honoraryContractRequestForm/honorary-candidate-detail/honorary-candidate-detail.module#HonoraryCandidateDetailModule' },
  { path: 'extensionhonorarycandidate', loadChildren: './honoraryContractRequestForm/honorary-candidate-extension/honorary-candidate-extension.module#HonoraryCandidateExtensionModule' },
  { path: 'confirmationfee', loadChildren: './honoraryContractRequestForm/confirmation-of-fee/confirmation-of-fee.module#ConfirmationOfFeeModule' },
  { path: 'approverdetails', loadChildren: './honoraryContractRequestForm/approver-detail/approver-detail.module#ApproverDetailModule' },
  { path: 'approvercandidatedetails', loadChildren: './honoraryContractRequestForm/approver-detail3/approver-detail3.module#ApproverDetail3Module' },
  { path: 'approverresult', loadChildren: './honoraryContractRequestForm/approver-detail2/approver-detail2.module#ApproverDetail2Module' },

  { path: 'haveSubmitted', loadChildren: './haveSubmitted/haveSubmitted.module#haveSubmittedPageModule' },
  { path: 'error', loadChildren: './error/error.module#errorPageModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
