export type NHSToNHSModel = {
  SubtantiveNHSEmployer: string;
}

export type SubstantiveModel = {
  AssignmentNumber: string;
}

export type RequestTypeModel = {
  TypeOfRequest: string;
}

export type ProfileModel = {
  ProfileId: string;
  ManagerName: string;
  ManagerEmail: string;
  ManagerPhoneNumber: string;
  ManagerJobTitle: string;
  ManagerDepartmentCostCode: string;
}

export type ContractModel = {
  PostTitle: string;
  StaffGroup: string;
  Department: string;
  HospitalSite: string;
  ExpectedStartDate?: Date;
  ExpectedEndDate?: Date;
  ExpectedDuration: string;
  ContractReason: string;
  DutiesAndResponsibilities: string;
  DirectPatientContact: string;
  Patient: string;
  CandidateUndertaking: string;
  RoleRequireProfessional: string;
  RegistrationBody: string;
  RegistrationNumber: string;
} 

export type ConfirmationFeeModel = {
  PayTheFee: string;
  BartsHealthDepartment: string;
  RequiredToPay?: boolean;
}

export type CandidateModel = {
  CandidateName: string;
  CandidateEmail: string;
}

export type ApproverModel = {
  ApproverName: string;
  ApproverJobTitle: string;
  ApproverDepartment: string;
  ApproverEmail: string;
}

export type ApplicantModel = {
  FullName: string;
  JobTitle: string;
  EmailAddress: string;
  ApplicantDate: Date;
  ApplicantName: string;
  NewDepartment: string;
}

export type CandidateDetailModel = {
  CountryCallingCode: string;
  Title: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  MobileTelephone: string;
  CandidateEmailAddress: string;
  DateOfBirth?: Date;
  Gender: string;
  PostCode: string;
  Address1: string;
  Town: string;
  Country: string;
  InternalExternal: string;
  PostCode1: string;
  CandidateFile?: File;
  CandidateAgreement?: boolean;
}

export type ExtensionModel = {
  CandidateAssignmentNumber: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  CandidateEmailAddress: string;
  ContactNumber: string;
  DOB?: Date;  
  ExpectedDuration: string;
}

export const dateFields = [
  'DOB',
  'ExpectedStartDate',
  'ExpectedEndDate',
  'ApplicantDate',
  'DateOfBirth'
];