import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AlertHelper } from '../common/alterhelper.service'
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { contractFormKey, honoraryForm } from './constant.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { FormService } from '../services/form.service';
import { environment } from 'src/environments/environment';
import { dateFields } from '../model/contract.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private router: Router,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private formService: FormService,
    private alertHelper:AlertHelper,
    private storage: Storage
  ) { }

  async quitForm() {
    this.alertHelper.presentAlertConfirm(
      "Are you sure that you want quit the form?",
      async () => {
        this.storage.remove("formData");
        this.storage.remove("manageformData");
        this.storage.get('queryParams').then((r)=>{
          if(r)
          {
            if(r.isFromPortal){
              this.router.navigate(["/"], { queryParams: { 'profileId': r.profileId,'isFromPortal':"1" } });
            }
            else
            {
              this.router.navigate(["/"], { queryParams: { 'profileId': r.profileId } });
            }
          }
          else
          {
            this.router.navigate(["/"]);
          }
        });
      }
    )
  };

  async formatDate(str) {
    if (!str) {
        return 0;
    }
    var arr = str.split("/");
    var date = new Date()
    date.setFullYear(arr[2], arr[1] - 1, arr[0]);
    return date;
  }

  generateFormGroupWithModel(model: any): FormGroup {
    const formGroup = new FormGroup({});
  
    for (const key in model) {
      if (model.hasOwnProperty(key)) {
        const control = new FormControl(model[key]);
        formGroup.addControl(key, control);
      }
    }
    return formGroup;
  }

  setDisabled(formGroup: FormGroup, arr: string[]) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        if (arr.includes(key)) {
          formGroup.controls[key].disable();
        }
      }
    }
  }

  setValidate(formGroup: FormGroup, validateList: any) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        if (validateList.hasOwnProperty(key)) {
          formGroup.controls[key].setValidators(validateList[key]);
        } 
      }
    }
  }

  setFormGroupValue(formGroup: FormGroup, model: any) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        if (model.hasOwnProperty(key)) {
          formGroup.controls[key].setValue(model[key]);
        }
      }
    }
  }

  setFormStorage(key: string, modelKey: string, value: any, callback?: Function) { 
    this.storage.get(key).then(data => {
      if (!data) {
        data = {};
      }
      data[modelKey] = value;
      this.storage.set(key, data);
      if (callback) {
        callback();
      }
    });
  }  

  convertDate(model: any) {
    for (const key in model) {
      if (model.hasOwnProperty(key)) {
        const value = model[key];
        if (value instanceof Date || this.isValidDate(value)) {
          //convert date to string
          model[key] = moment(value).format('YYYY-MM-DD');
        }
        else if (dateFields.indexOf(key) != -1) { 
          model[key] = null;
        }
      }
    }
    return model; 
  }    

  isValidDate(str: string): boolean {
    const reg = /^(\d{2}) (\w{3}) (\d{4})$/;
    const regDate = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/;
    return reg.test(str) || regDate.test(str);
  }

  compareModel(model1: any, model2: any) {
    for (const key in model1) {
      if (model1.hasOwnProperty(key)) {
        const value = model1[key];
        if (model2.hasOwnProperty(key)) {
          if (value instanceof Date || this.isValidDate(value)) {
            //convert date to string
            model2[key] = moment(value).format('DD MMM yyyy');
          }
          else if(!model2[key] || model2[key] === ''){ 
            model2[key] = value;
          }
        }
      }
    }
  }

  setProgressModel<T>(formGroup: FormGroup, profileId: string, model: T) {
    this.storage.get(honoraryForm + profileId).then((data: any) => {
      if (data) { 
        this.compareModel(data, model);
        formGroup.patchValue(model);
      }
    })
  }

  setProgressCacheModel(model: any, profileId: string, callback: Function) {
    if(profileId){
      this.setCacheModel(model, profileId, callback);
    }
    else{
      this.storage.get("userprofile").then(async (data: any) => {
        if(data && data.profileId){
          this.setCacheModel(model, data.profileId, callback);
        }
        else{
          this.alertHelper.presentAlertNoTitle("Your Profile Id is missing, please login again.");
        }
      })
    }
  }

  setCacheModel(model: any, profileId: string, callback: Function) {
    this.storage.get(honoraryForm + profileId).then(formData => {
      if(!formData) formData = {};
      for (const key in model) {
        if (model.hasOwnProperty(key)) {
          const value = model[key];
          formData[key] = value;
        }
      }
      this.storage.set(honoraryForm + profileId, formData).then(
        () => {
          if(callback) callback();
        }
      );
    });
  }

  quitHCForm(profileId: string) { 
    this.alertHelper.presentAlertConfirm(
      "Are you sure that you want quit the form? All the form data will save to server and you can continue the form later.",
      async () => {
        if(profileId){
          await this.submitProgressForm(profileId);
        }
        else{
          this.storage.get("userprofile").then(async (data: any) => {
            if(data && data.profileId){
              await this.submitProgressForm(data.profileId);
            }
            else{
              await this.alertHelper.presentAlertNoTitle("Your Profile Id is missing, please login again.");
            }
          })
        }
      }
    )
  }

  async submitProgressForm(profileId){
    this.storage.get(honoraryForm + profileId).then(async (formdata: any) => {
      const formData = Object.assign(
        formdata,
        { ProfileId: profileId }
      );
      const loading = await this.loadingController.create({
        message: 'Loading...',
        spinner: "circles",
        mode: "md"
      });
      await loading.present();
      this.formService.SubmitContractProgressForm(this.convertDate(formData), {}).subscribe(async () => {
        await loading.dismiss();
        this.storage.remove(honoraryForm + profileId).then(() => { 
          if (environment.formType == 'hc') {
            this.router.navigate(["/startform"], { queryParams: { 'profileId': profileId } });
          }
          else { 
            this.router.navigate(["/"], { queryParams: { 'profileId': profileId,'isFromPortal':"1" } });
          }
        });
        this.storage.remove(contractFormKey + profileId);
      });
    });
  }

  async signout(profileId) { 
    const alert = await this.alertController.create({
      keyboardClose: false,
      backdropDismiss:false,
      message: "Are you sure that you want to Sign out?",
      buttons: [{
        text: 'YES',        
        cssClass: 'secondary',
        handler: async (blah) => {
          await alert.present();
          this.router.navigate(["/login"]);
          this.storage.remove("queryParams");
          this.storage.remove(honoraryForm + profileId);
          this.storage.remove("userprofile");
        }
      }, {
        text: 'NO',
        role: 'cancel',
        handler: () => {
        }
      }]
    });

    await alert.present();
  }

  checkLogin(callback?: Function) { 
    this.storage.get('userprofile').then(async (userprofile: any) => {
      if (!userprofile || !userprofile.profileId) { 
        this.router.navigate(["/login"]);
      }
      else if(callback != null){
        callback(userprofile.profileId);
      }
    })
  }
  
  //IOS mobile
  _isIOS = -1;
  isKeyboardOpen = false;
  isFocusProcessing = false;
  addListener() {
    const isIOS_Platform = this.isIOS();
    if (isIOS_Platform) {
      setTimeout(() => {
        const inputs = document.querySelectorAll('input, textarea');
  
        inputs.forEach(input => {
          input.addEventListener('focus', this.onFocus);
          input.addEventListener('blur', this.onBlur);
        });
      }, 500)
    }
  }
  onFocus = (event) => {
    if (!this.isKeyboardOpen) { 
      const container = document.querySelector('ion-content');
      if(!container.classList.contains('keyboard-open')){
        container.classList.add('keyboard-open');
      }
      this.isKeyboardOpen = true;
    }
    this.isFocusProcessing = true;
    setTimeout(() => {
      this.isFocusProcessing = false;
    }, 300);
  }

  onBlur = (event) => {
    if (this.isKeyboardOpen) { 
      setTimeout(() => {
        if (this.isKeyboardOpen && !this.isFocusProcessing) { 
          this.isKeyboardOpen = false;
          const container = document.querySelector('ion-content');
          if(container.classList.contains('keyboard-open')){
            container.classList.remove('keyboard-open');
          }
        }
      }, 100)
    }
  }  
  isIOS() {
    const ua = typeof window === 'object' ? window.navigator.userAgent : '';
    if (this._isIOS === -1) {
      this._isIOS = /iPhone|iPod|iPad/i.test(ua) ? 1 : 0;
    }
    return this._isIOS === 1;
  }
}
