import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  // apiUrl = 'https://shinepaperless-staging.azurewebsites.net/api/';
  // apiUrl = 'http://192.168.1.123:9030/api/';
  constructor(private storage: Storage, private http: HttpClient) { }
  baseUrl = environment.baseUrl;
  submitStudyLeaveForm (data): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'SubmitStudyLeave', data)
      .pipe(
        tap(_ => this.log('submitStudyLeaveForm')),
        catchError(this.handleError('submitStudyLeaveForm', []))
      );
  }

  submitApprenticeship (data): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'SubmitApprenticeship', data)
      .pipe(
        tap(_ => this.log('SubmitApprenticeship')),
        catchError(this.handleError('SubmitApprenticeship', []))
      );
  }

  SubmitBartsRAFormPartA (data): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'SubmitBartsRAFormPartA', data)
      .pipe(
        tap(_ => this.log('SubmitBartsRAFormPartA')),
        catchError(this.handleError('SubmitBartsRAFormPartA', []))
      );
  }

  SubmitBartsRAFormPartB (data): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'SubmitBartsRAFormPartB', data)
      .pipe(
        tap(_ => this.log('SubmitBartsRAFormPartB')),
        catchError(this.handleError('SubmitBartsRAFormPartB', []))
      );
  }

  checkUserRAForm(request):Observable<any> {
    return this.http.post<any>(this.baseUrl + 'CheckExistRAForm', request)
      .pipe(
        tap(_ => this.log('CheckExistRAForm')),
        catchError(this.handleError('CheckExistRAForm', []))
      );
  }

  submitRAForm (data): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'SubmitBartsRAForm', data)
      .pipe(
        tap(_ => this.log('SubmitBartsRAForm')),
        catchError(this.handleError('SubmitBartsRAForm', []))
      );
  }  

  // FIT Booking
  FITBookingAPI_GetAvailableDates (data, header): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'GetAvailableDates', data, {headers: header})
      .pipe(
        tap(_ => this.log('GetAvailableDates')),
        catchError(this.handleError('GetAvailableDates', []))
      );
  }

  FITBookingAPI_GetOptions(data, header) : Observable<any>{
    return this.http.post<any>(this.baseUrl + 'GetDropdownList', data, {headers: header})
      .pipe(
        tap(_ => this.log('GetDropdownList')),
        catchError(this.handleError('GetDropdownList', []))
      );
  }

  FITBookingAPI_GetTimeSlots(data, header) : Observable<any>{
    return this.http.post<any>(this.baseUrl + 'GetTimeSlots', data, {headers: header})
      .pipe(
        tap(_ => this.log('GetTimeSlots')),
        catchError(this.handleError('GetTimeSlots', []))
      );
  }

  FITBookingAPI_SubmitTimeSlots(data, header) : Observable<any>{
    return this.http.post<any>(this.baseUrl + 'SubmitTimeSlots', data, {headers: header})
      .pipe(
        tap(_ => this.log('SubmitTimeSlots')),
        catchError(this.handleError('SubmitTimeSlots', []))
      );
  }

  //Contract Form
  SubmitContractProgressForm(data, header) : Observable<any>{
    return this.http.post<any>(this.baseUrl + 'SubmitContractProgressForm', data, {headers: header})
      .pipe(
        tap(_ => this.log('SubmitContractProgressForm')),
        catchError(this.handleError('SubmitContractProgressForm', []))
      );
  }

  SubmitContractForm(data, header) : Observable<any>{
    return this.http.post<any>(this.baseUrl + 'SubmitContractForm', data, {headers: header})
      .pipe(
        tap(_ => this.log('SubmitContractForm')),
        catchError(this.handleError('SubmitContractForm', []))
      );
  }

  SubmitContractStatusForm(data, header) : Observable<any>{
    return this.http.post<any>(this.baseUrl + 'SubmitStatusForm', data, {headers: header})
      .pipe(
        tap(_ => this.log('SubmitStatusForm')),
        catchError(this.handleError('SubmitStatusForm', []))
      );
  }

  SubmitContractCompleteForm(file: any, data: any): Observable<any> {
    const formData: FormData = new FormData();
    if (file) { 
      formData.append('CandidateFile', file, file.name);
    }
    for (const key in data) {
      if (data.hasOwnProperty(key) && key != 'CandidateFile') {
        formData.append(key, data[key]);
      }
    }
    return this.http.post<any>(this.baseUrl + 'SubmitContractCompleteForm', formData)
      .pipe(
        tap(_ => this.log('SubmitContractCompleteForm')),
        catchError(this.handleError('SubmitContractCompleteForm', []))
      );
  }

  GetProgressContractForm(data, header) : Observable<any>{
    return this.http.post<any>(this.baseUrl + 'GetProccessContractForm', data, {headers: header})
      .pipe(
        tap(_ => this.log('GetProccessContractForm')),
        catchError(this.handleError('GetProccessContractForm', []))
      );
  }

  GetContractForm(data, header) : Observable<any>{
    return this.http.post<any>(this.baseUrl + 'GetContractForm', data, {headers: header})
      .pipe(
        tap(_ => this.log('GetContractForm')),
        catchError(this.handleError('GetContractForm', []))
      );
  }

  GetAddressByPostCode(postCode: string): Observable<any> {
    return this.http.get('https://api.postcodes.io/postcodes/' + postCode)
      .pipe(
        tap(_ => this.log('GetAddressByPostCode')),
        catchError(this.handleError('GetAddressByPostCode', []))
      );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }
}
